import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ParamsService {

  params;

  showHeader = true;

  constructor() { }
}
