import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoggedInGuard } from './shared/guards/loggedIn/loggedIn.guard';
import { LoginGuard } from './shared/guards/needLogin/login.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'foodtrucks',
    pathMatch: 'full'
  },
  {
    path: 'foodtrucks',
    loadChildren: () => import('./modules/foodtrucks/foodtrucks.module').then( m => m.FoodtrucksModule),
    canActivate: [
      LoggedInGuard
    ],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'cart',
    loadChildren: () => import('./modules/cart/cart.module').then( m => m.CartModule),
    canActivate: [
      LoggedInGuard
    ],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'orders',
    loadChildren: () => import('./modules/orders/orders.module').then( m => m.OrdersModule),
    canActivate: [
      LoggedInGuard
    ],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then( m => m.LoginModule),
    canActivate: [
      LoginGuard
    ],
  },
  {
    path: 'register',
    redirectTo: 'login/register',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'foodtrucks',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
