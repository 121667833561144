import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/** PIPES */
import { ImageFoodtruckPipe } from '../shared/pipes/imageFoodtruck/imageFoodtruck.pipe';
import { FilterPipe } from '../shared/pipes/filter/filter.pipe';
import { ImageProductPipe } from '../shared/pipes/imageProduct/imageProduct.pipe';

/** COMPONENTS */
import { ButtonComponent } from '../shared/components/custom/button/button.component';
import { CheckboxComponent } from '../shared/components/custom/checkbox/checkbox.component';
import { TooltipErrorComponent } from '../shared/components/tooltipError/tooltipError.component';
import { CartFabButtonComponent } from '../shared/components/cartFabButton/cartFabButton.component';
import { SearchbarComponent } from '../shared/components/searchbar/searchbar.component';
import { SkeletonComponent } from '../shared/components/skeleton/skeleton.component';
import { ContextmenuComponent } from '../shared/components/custom/contextmenu/contextmenu.component';
import { ScannerComponent } from '../shared/components/scanner/scanner.component';
import { ProductOrderCardComponent } from '../shared/components/productOrderCard/productOrderCard.component';

@NgModule({
  declarations: [
    ImageFoodtruckPipe,
    FilterPipe,
    ButtonComponent,
    CheckboxComponent,
    TooltipErrorComponent,
    CartFabButtonComponent,
    SearchbarComponent,
    SkeletonComponent,
    ContextmenuComponent,
    ScannerComponent,
    ImageProductPipe,
    ProductOrderCardComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    RouterModule,
    TranslateModule,
    IonicModule,
    CommonModule,
    ImageFoodtruckPipe,
    FilterPipe,
    ButtonComponent,
    CheckboxComponent,
    FormsModule,
    ReactiveFormsModule,
    TooltipErrorComponent,
    CartFabButtonComponent,
    SearchbarComponent,
    SkeletonComponent,
    ContextmenuComponent,
    ScannerComponent,
    ImageProductPipe,
    ProductOrderCardComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModule { }
