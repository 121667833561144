/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface Product {
  id: string;
  name: string;
  price: number;
  type: string;
  description: string;
  image: string;
  foodtruck: string;
  amount: number;
  allergies: string;
  annotation: string;
}

export interface Foodtruck {
  id: string;
  name: string;
  type: string;
  image: string;
}

@Injectable({
  providedIn: 'root'
})
export class CartService {

  public url = 'https://caapi.woutick.es';

  public products: Product[] = [];

  private cart: Product[] = [];
  private cartItemCount = new BehaviorSubject(0);

  constructor(
    private http: HttpClient
  ) { }

  public getProducts(id): Promise<any> {
    return this.http.get(this.url + '/congress/products/' + id, {
      headers: new HttpHeaders({
        'X-Access-Token': 'Bearer ' + localStorage.getItem('selecteduserJWT')
      })
    }).toPromise().then((result) => {
      const products = result['products_idfoodtruck'];
      products.forEach(product => {
        this.setProduct(product);
      });
      return result['products_idfoodtruck'];
    }, (err) => err);
  }

  public getProductsById(id): Promise<any> {
    return this.http.get(this.url + '/congress/products/' + id, {
      headers: new HttpHeaders({
        'X-Access-Token': 'Bearer ' + localStorage.getItem('selecteduserJWT')
      })
    }).toPromise().then((result) => result['products_idfoodtruck'], (err) => err);
  }

  public getAllProducts(): Promise<any> {
    return this.http.get(this.url + '/congress/allproducts', {
      headers: new HttpHeaders({
        'X-Access-Token': 'Bearer ' + localStorage.getItem('selecteduserJWT')
      })
    }).toPromise().then((result) => result['products'], (err) => err);
  }

  public sendOrder(method: string, site: string, exchange: string): Promise<any> {
    return this.http.post(this.url + '/congress/petition',
    JSON.stringify(this.getInfoToPetition(method, site, exchange)),
    {
      headers: new HttpHeaders({
        'X-Access-Token': 'Bearer ' + localStorage.getItem('selecteduserJWT')
      })
    }).toPromise().then((result) => result, (err) => err);
  }

  public getOrdersHistory(): Promise<any> {
    return this.http.get(this.url + '/congress/historypayments', {
      headers: new HttpHeaders({
        'X-Access-Token': 'Bearer ' + localStorage.getItem('selecteduserJWT')
      })
    }).toPromise().then((result) => result['petition'], (err) => err);
  }

  setInitialCart() {
    if (sessionStorage.getItem('cart')) {
      this.cart = JSON.parse(sessionStorage.getItem('cart'));
    } else {
      sessionStorage.setItem('cart', JSON.stringify(this.cart));
    }
  }

  getInfoToPetition(method: string, site: string, exchange) {
    const lines = [];
    this.cart.forEach(product => {
      lines.push({
        foodtruck: product.foodtruck,
        id_product: product.id,
        price: product.price,
        quantity: product.amount,
        annotations: product.annotation
      });
    });
    return {
      method,
      total_order: this.getTotal(),
      site,
      exchange,
      lines
    };
  }

  getCart() {
    return this.cart;
  }

  getCartItemCount() {
    return this.cartItemCount;
  }

  cancelOrder() {
    this.cart = [];
    this.cartItemCount = new BehaviorSubject(0);
  }

  getTotal() {
    return this.cart.reduce((i, j) => i + j.price * j.amount, 0);
  }

  addProduct(product) {
    let added = false;
    for (const p of this.cart) {
      if (p.id === product.id) {
        p.amount += 1;
        added = true;
        break;
      }
    }
    if (!added) {
      product.amount = 1;
      this.cart.push(product);
    }
    this.cartItemCount.next(this.cartItemCount.value + 1);
  }

  decreaseProduct(product) {
    let flag = false;
    for (const [index, p] of this.cart.entries()) {
      if (p.id === product.id) {
        p.amount -= 1;
        flag = true;
        if (p.amount === 0) {
          this.cart.splice(index, 1);
        }
      }
    }
    if (flag) {
      this.cartItemCount.next(this.cartItemCount.value - 1);
    }
  }

  removeProduct(product) {
    for (const [index, p] of this.cart.entries()) {
      if (p.id === product.id) {
        this.cartItemCount.next(this.cartItemCount.value - p.amount);
        this.cart.splice(index, 1);
      }
    }
  }

  setProduct(product) {
    this.products.push({
      id: product.id,
      name: product.name,
      description: product.description,
      type: product.type,
      amount: parseFloat(product.amount),
      foodtruck: product.id_foodtruck,
      image: product.image,
      price: parseFloat(product.price),
      allergies: product.allergies,
      annotation: ''
    });
  }

  getProductsByCategory(type = 'all') {
    const products = [];
    if (type === 'all') {
      return this.products.sort((a, b) => {
        if (this.returnPos(a.type) < this.returnPos(b.type)) {
          return 1;
        }
        if (this.returnPos(a.type) > this.returnPos(b.type)) {
          return -1;
        }
        if (this.takeOffAccents(a.name.toUpperCase().trim()) > this.takeOffAccents(b.name.toUpperCase().trim())) {
          return 1;
        }
        if (this.takeOffAccents(a.name.toUpperCase().trim()) < this.takeOffAccents(b.name.toUpperCase().trim())) {
          return -1;
        }
        return 0;
      });
    }
    this.products.forEach(product => {
      if (product.type === type) {
        products.push(product);
      }
    });
    return products.sort((a, b) => {
      if (this.takeOffAccents(a.name.toUpperCase().trim()) > this.takeOffAccents(b.name.toUpperCase().trim())) {
        return 1;
      }
      if (this.takeOffAccents(a.name.toUpperCase().trim()) < this.takeOffAccents(b.name.toUpperCase().trim())) {
        return -1;
      }
      return 0;
    });
  }

  returnPos(type) {
    switch (type) {
      case 'dessert':
        return 1;
      case 'drink':
        return 2;
      case 'food':
        return 3;
      case 'menu':
        return 4;
    }
  }

  takeOffAccents(value) {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
}
